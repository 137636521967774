import React from "react";
import useDialog from "../hooks/useDialog";
import { useRefCallBack } from "../hooks/useRefCallBack";
import { useConfigView } from "../Config.context";
import {
  createCampaignInitialValues,
  getCampaignSteps,
  getHeaderActions,
  getHeaderControlAction,
  resolveEjsTags,
} from "./CampaignPage.helpers";
import {
  ActivityDetailsView,
  CampaignCreateRequestView,
  CampaignResponseView,
  CampaignSaveAction,
  Step,
} from "./CampaignPage.types";
import {
  EditorType,
  Template,
  TemplateType,
} from "../models/template/template.types";
import {
  useGetCampaignActivitiesByIdQuery,
  useGetCampaignByIdQuery,
  usePostCampaignMutation,
  usePutCampaignActivitiesEmailMessageByCampaignIdMutation,
  usePutCampaignByIdMutation,
  usePutCampaignSegmentsByIdMutation,
  usePutCampaignSenderDetailsByIdMutation,
  usePutCampaignStartByIdMutation,
  usePutCampaignUnscheduleByIdMutation,
} from "../app/useGeneratedApi";
import { extractTemplateData } from "../models/template/templateUtils";
import { StripoInstance } from "../components/Stripo/Stripo.types";
import { SegmentView, SenderDetailsIdentityView } from "../app/GeneratedApi";

export function useCampaignPage() {
  const { config } = useConfigView();

  const activityTemplateAttributesData = config.defaultTemplateAttributes;
  const [campaignData, setCampaignData] = React.useState<CampaignResponseView>(
    createCampaignInitialValues()
  );
  const [activeStep, setActiveStep] = React.useState<Step>("details");
  const [detailsCompleted, setDetailsCompleted] =
    React.useState<boolean>(false);
  const [designCompleted, setDesignCompleted] = React.useState<boolean>(false);
  const [audienceCompleted, setAudienceCompleted] =
    React.useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<ActivityDetailsView>();
  const [requireSave, setRequireSave] = React.useState<CampaignSaveAction>({});
  const [sendEmailTestOpen, setSendEmailTestOpen] = React.useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = React.useState(false);
  const [scheduleDialogOpen, setScheduleDialogOpen] = React.useState(false);
  const [saveTemplateDialogOpen, setSaveTemplateDialogOpen] =
    React.useState(false);
  const [templateLoaded, setTemplateLoaded] = React.useState(false);
  const [templateEditing, setTemplateEditing] = React.useState(false);
  const [templateHistoryVisible, setTemplateHistoryVisible] =
    React.useState(false);
  const [templateType, setTemplateType] = React.useState<TemplateType>();
  const [templateEditorType, setTemplateEditorType] =
    React.useState<EditorType>();
  const [screenLoading, setScreenLoading] = React.useState(false);

  const {
    dialogVisible: quitCampaignVisible,
    hideDialog: hideQuitCampaign,
    showDialog: showQuitCampaign,
  } = useDialog();

  const [id, setId] = React.useState(config.campaignId || "");

  const [selectedAudiences, setSelectedAudiences] = React.useState<
    SegmentView[]
  >([]);

  const {
    data: campaignFetchedData,
    refetch: refetchCampaign,
    isLoading: isCampaignLoading,
    isSuccess: isCampaignSuccess,
  } = useGetCampaignByIdQuery(id!, {
    enabled: !!id,
    initialData: !id ? createCampaignInitialValues() : undefined,
    useErrorBoundary: true,
    onSuccess: (data) => {
      smartAssistRef.current = data.smartAssistant ?? false;
      setCampaignData((prev) => ({
        ...prev,
        ...data,
      }));

      const isDetailsStep = config.campaignId
        ? !data.subject
        : !data.subject || !data.contentSourceUrl;
      if (isDetailsStep) {
        setActiveStep("details");
      } else if (!data.audiences?.length) {
        setDetailsCompleted(true);
        setActiveStep("design");
      } else {
        setDesignCompleted(true);
        setAudienceCompleted(true);
        setActiveStep("schedule");
      }
      if (data.audiences?.length) {
        setSelectedTemplate((prev) => ({
          ...prev,
          ...data.template,
        }));
        setTemplateEditing(true);
        setTemplateEditorType("DRAG_AND_DROP");
      }
      setSelectedAudiences(data.audiences || []);
    },
  });

  const campaignStatus = campaignData?.campaignStatus;
  const title = campaignData?.name || "";
  const isCampaignCreated = !!id || campaignData?.subject;
  const activityId = "";
  const templateHtml = selectedTemplate?.templateHtml;
  const templateCss = selectedTemplate?.templateCss;

  const stripoRef = React.useRef<StripoInstance>(null);
  const compressRef = React.useRef(false);
  const smartAssistRef = React.useRef(false);

  const openSendEmailTest = React.useCallback(async () => {
    setSendEmailTestOpen(true);
  }, []);

  const closeSendEmailTest = React.useCallback(() => {
    setSendEmailTestOpen(false);
  }, []);

  const openPreviewDialog = React.useCallback(() => {
    setPreviewDialogOpen(true);
  }, []);

  const openSavetemplateDialog = React.useCallback(() => {
    setSaveTemplateDialogOpen(true);
  }, []);

  const closePreviewDialog = React.useCallback(() => {
    setPreviewDialogOpen(false);
  }, []);

  const openScheduleDialog = React.useCallback(() => {
    setScheduleDialogOpen(true);
  }, []);

  const closeScheduleDialog = React.useCallback(() => {
    setScheduleDialogOpen(false);
  }, []);

  const closeSaveTemplateDialog = React.useCallback(() => {
    setSaveTemplateDialogOpen(false);
  }, []);

  const [refetchTemplateSections, setRefetchTemplateSections] = React.useState<
    string[]
  >([]);

  const [shouldCreateHistoryPoint, setShouldCreateHistoryPoint] =
    React.useState(false);

  const steps = React.useMemo(() => {
    const detailsCompleted = id ? !!campaignFetchedData?.subject : false;
    const designCompleted = id
      ? !!campaignFetchedData?.template && !!selectedTemplate
      : false;

    return getCampaignSteps(
      id,
      detailsCompleted,
      designCompleted,
      audienceCompleted,
      smartAssistRef.current
    );
  }, [
    campaignData,
    detailsCompleted,
    designCompleted,
    audienceCompleted,
    id,
    smartAssistRef,
  ]);

  const firstIncompleteStep = React.useMemo(
    () => steps.find((s) => !s.completed)?.step,
    [steps]
  );
  const continueDetails = React.useCallback(() => {
    setActiveStep("design");
  }, []);

  const {
    data: campaignActivitiesData,
    isLoading: isCampaignActivitiesLoading,
  } = useGetCampaignActivitiesByIdQuery(id!, {
    enabled: !!campaignData?.id,
    onSuccess: (data) => {
      if (data.activities?.length) {
        setSelectedTemplate(data.activities?.[0]);
      }
    },
  });

  const attributes = activityTemplateAttributesData?.attributes;
  const campaignTemplate = campaignActivitiesData?.activities?.[0];

  const nextStepDisabled = React.useMemo(() => {
    const activeCampaignStep = steps.find((cs) => cs.step === activeStep);
    if (["details", "audience"].includes(activeStep)) {
      return !activeCampaignStep?.completed;
    } else if (activeStep === "design") {
      return selectedTemplate ? !templateLoaded : true;
    } else {
      return true;
    }
  }, [activeStep, selectedTemplate, steps, templateLoaded, isCampaignSuccess]);

  const changeTemplate = useRefCallBack(
    React.useCallback(() => {
      setTemplateEditing(false);
    }, [])
  );

  const headerActions = React.useMemo(() => {
    return getHeaderActions(
      campaignData?.campaignStatus,
      activeStep,
      !!selectedTemplate,
      templateLoaded,
      activityId,
      {
        changeTemplate,
        openSendEmailTest,
        openPreviewDialog,
        openSavetemplateDialog,
      }
    );
  }, [
    activeStep,
    activityId,
    changeTemplate,
    campaignData?.campaignStatus,
    openPreviewDialog,
    openSavetemplateDialog,
    openSendEmailTest,
    selectedTemplate,
    templateLoaded,
  ]);

  const saveStep = React.useCallback(() => {
    Object.keys(requireSave).forEach((k) => requireSave[k]?.());
  }, [requireSave]);

  const quitCampaign = React.useCallback(() => {
    if (campaignData?.subject) {
      showQuitCampaign();
    } else {
      if (config.exitWithoutSaving) config.exitWithoutSaving();
      return;
    }
  }, [showQuitCampaign, campaignData]);

  const toggleTemplateHistory = React.useCallback(() => {
    setTemplateHistoryVisible((prev) => !prev);
  }, []);

  const clearRequireSave = React.useCallback(() => {
    setRequireSave({});
  }, []);

  const saveDetails = React.useCallback(
    async (campaignData: CampaignCreateRequestView) => {
      setCampaignData((prev) => ({
        ...prev,
        ...campaignData,
      }));
      await saveCampaign(campaignData);
    },
    [setCampaignData, campaignData]
  );

  const {
    mutateAsync: postCampaignMutateAsync,
    isLoading: isPostCampaignLoading,
  } = usePostCampaignMutation();

  const {
    mutateAsync: putCampaignMutateAsync,
    isLoading: isPutCampaignLoading,
  } = usePutCampaignByIdMutation();

  const {
    mutateAsync: putCampaignSenderDetailsMutateAsync,
    isLoading: isPutCampaignSenderDetailsLoading,
  } = usePutCampaignSenderDetailsByIdMutation();

  const { mutateAsync: putCampaignUnscheduleMutateAsync } =
    usePutCampaignUnscheduleByIdMutation();

  const assignSender = useRefCallBack(
    React.useCallback(
      async (sender: SenderDetailsIdentityView, options?: any) => {
        if (id) {
          await putCampaignSenderDetailsMutateAsync(
            {
              id,
              data: sender,
            },
            options
          );
          await refetchCampaign();
        }
      },
      [id, putCampaignSenderDetailsMutateAsync, refetchCampaign]
    )
  );

  const { mutateAsync: putCampaignSegmentsMutateAsync } =
    usePutCampaignSegmentsByIdMutation();

  const assignAudiences = useRefCallBack(
    React.useCallback(
      async (options?: any) => {
        if (id && config.audiences?.length) {
          const segments: SegmentView[] = config.audiences?.map((item) => ({
            id: item.id,
            name: item.name,
            audienceType: item.type || "UPLOAD",
            numberOfCustomers: item.numberOfCustomers,
          }));
          await putCampaignSegmentsMutateAsync(
            {
              id,
              data: { audiences: segments },
            },
            options
          );
          setSelectedAudiences(segments);
          setAudienceCompleted(true);
        }
      },
      [id, putCampaignSegmentsMutateAsync]
    )
  );

  const saveCampaign = React.useCallback(
    async (details: CampaignCreateRequestView) => {
      if (id) {
        await putCampaignMutateAsync({
          id,
          data: {
            ...details,
            smartAssistant: smartAssistRef.current,
            generatedBy: "User",
          },
        });
        await refetchCampaign();
      } else {
        const data = await postCampaignMutateAsync({
          data: {
            ...details,
            smartAssistant: smartAssistRef.current,
            generatedBy: "User",
          },
        });
        setId(data.id || "");
      }
    },
    [id, postCampaignMutateAsync, putCampaignMutateAsync, refetchCampaign]
  );
  const notifyRequireSave = React.useCallback(
    (action: { name: string; fn?: () => void }) => {
      setRequireSave((actions) => ({ ...actions, [action.name]: action.fn }));
    },
    []
  );

  const { mutateAsync: putCampaignActivitiesEmailMessageMutateAsync } =
    usePutCampaignActivitiesEmailMessageByCampaignIdMutation();

  const saveTemplate = React.useCallback(
    async (template: ActivityDetailsView, force?: boolean) => {
      if (
        force !== true &&
        selectedTemplate?.templateHtml === template.templateHtml &&
        selectedTemplate?.templateCss === template.templateCss &&
        selectedTemplate?.id === template.id
      ) {
        return;
      }

      !selectedAudiences.length && (await assignAudiences());
      const { id: templateId } =
        await putCampaignActivitiesEmailMessageMutateAsync({
          campaignId: id!,
          data: template,
        });
      compressRef.current = false;
      setSelectedTemplate({
        ...template,
        id: templateId,
      });
    },
    [
      putCampaignActivitiesEmailMessageMutateAsync,
      id,
      selectedTemplate?.id,
      selectedTemplate?.templateCss,
      selectedTemplate?.templateHtml,
    ]
  );

  const getAndSaveTemplate = React.useCallback(
    async (force?: boolean) => {
      return new Promise<void>((resolve, reject) => {
        if (!selectedTemplate) {
          resolve();
        }
        stripoRef.current?.getTemplate(async (html: string, css: string) => {
          const data: ActivityDetailsView = {
            ...selectedTemplate,
            templateHtml: html,
            templateCss: css,
            tags: [],
            compressTemplate: compressRef.current,
          };
          try {
            await saveTemplate(data, force);
            resolve();
          } catch (e: any) {
            reject(e);
          }
        });
      });
    },
    [saveTemplate, selectedTemplate]
  );

  const selectTemplate = useRefCallBack(
    React.useCallback(
      async (template: Template, saved?: boolean, type?: TemplateType) => {
        setTemplateType(type);
        const nextEditorType = (template as any).editorType;
        setTemplateEditorType(nextEditorType || "DRAG_AND_DROP");
        // if (campaignTemplate && template.id !== campaignTemplate.id) {
        //   showConfirm(
        //     createChangeTemplateConfirmOptions(async () => {
        //       const nextTemplate = await resolveEjsTags(
        //         template,
        //         undefined,
        //         saved,
        //         type,
        //         attributes
        //       );
        //       await saveTemplate(nextTemplate);
        //       await refetchCampaignActivities();
        //       setTemplateEditing(true);
        //       setShouldCreateHistoryPoint(true);
        //     })
        //   );
        // } else {
        const templateId =
          template.id === campaignTemplate?.id ? template.id : undefined;
        const nextTemplate = await resolveEjsTags(
          template,
          templateId,
          saved,
          type,
          attributes
        );
        await saveTemplate(nextTemplate);
        setTemplateEditing(true);
        if (template.id !== campaignTemplate?.id) {
          setShouldCreateHistoryPoint(true);
        }
        // }
      },
      [attributes, campaignTemplate, id, saveTemplate]
    )
  );

  const handleTemplateLoaded = useRefCallBack(() => {
    setScreenLoading(true);
    setTemplateLoaded(true);
    if (shouldCreateHistoryPoint) {
      stripoRef.current?.createVersionHistoryPointAsync();
      setShouldCreateHistoryPoint(false);
    }
    setScreenLoading(false);
  });

  const handleTemplateUnLoaded = React.useCallback(() => {
    setTemplateLoaded(false);
  }, []);

  const handleTemplateChange = React.useCallback(() => {
    getAndSaveTemplate();
  }, [getAndSaveTemplate]);

  const hideTemplateHistory = React.useCallback(() => {
    setTemplateHistoryVisible(false);
  }, []);

  const selectCustomTemplate = React.useCallback(
    async (html: string, css: string) => {
      setTemplateType(undefined);
      setTemplateEditorType("HTML");
      const nextTemplate: ActivityDetailsView = {
        name: campaignData?.name,
        templateHtml: html,
        templateCss: css,
        editorType: "HTML",
      };
      setSelectedTemplate(nextTemplate);
      setShouldCreateHistoryPoint(true);
      setTemplateEditing(true);
    },
    [campaignData?.name]
  );
  const templateData = React.useMemo(
    () => extractTemplateData(attributes),
    [attributes]
  );

  const continueDesign = React.useCallback(async () => {
    try {
      compressRef.current = true;
      await getAndSaveTemplate(true);
      setDesignCompleted(true);
      setActiveStep("schedule");
    } finally {
      return;
    }
  }, [getAndSaveTemplate, id]);

  const emailMessage = React.useMemo(
    () => ({
      templateType,
      campaignTemplate,
      attributes,
      templateData,
    }),
    [attributes, campaignTemplate, templateData, templateType]
  );

  const { mutateAsync: putCampaignStartMutateAsync } =
    usePutCampaignStartByIdMutation({
      onSuccess: async (_) => {
        await refetchCampaign();
        config.exitWithoutSaving && config.exitWithoutSaving();
      },
    });

  const startCampaign = React.useCallback(
    async (
      id: string,
      start?: number,
      days?: string[],
      time?: string,
      options?: any
    ) => {
      try {
        await putCampaignStartMutateAsync(
          { id, data: { start, isScheduled: !!start, days, time } },
          options
        );
      } finally {
        await refetchCampaign();
      }
    },
    [putCampaignStartMutateAsync, refetchCampaign]
  );

  const cancelScheduledCampaign = React.useCallback(
    async (id: string) => {
      try {
        await putCampaignUnscheduleMutateAsync({ id });
      } finally {
        await refetchCampaign();
      }
    },
    [putCampaignUnscheduleMutateAsync, refetchCampaign]
  );
  const gotoNextStep = React.useCallback(() => {
    if (activeStep === "details") {
      if (designCompleted) {
        setActiveStep("schedule");
      } else {
        continueDetails();
      }
    } else if (activeStep === "design") {
      continueDesign();
    } else {
      return;
    }
  }, [activeStep, continueDesign, continueDetails, firstIncompleteStep, id]);

  const headerControlAction = React.useMemo(() => {
    return getHeaderControlAction(
      campaignData?.campaignStatus,
      activeStep,
      nextStepDisabled,
      {
        gotoNextStep,
        sendCampaign: () => id && startCampaign(id),
        scheduleCampaign: openScheduleDialog,
        saveForLater: () => {
          quitCampaign();
          return;
        },
        cancelScheduled: () => id && cancelScheduledCampaign(id),
      },
      false
    );
  }, [
    activeStep,
    cancelScheduledCampaign,
    campaignData?.campaignStatus,
    gotoNextStep,
    id,
    nextStepDisabled,
    openScheduleDialog,
    startCampaign,
  ]);

  React.useEffect(() => {
    if (id) {
      !campaignData?.senderDetailsId &&
        assignSender({
          senderDetailsId: config?.senderDetails?.id,
          senderEmail: config?.senderDetails?.email,
          senderName: config?.senderDetails?.name,
        });
    }
  }, [id]);

  React.useEffect(() => {
    if (activeStep === "design") {
      if (campaignTemplate) {
        setSelectedTemplate(campaignTemplate);
        setTemplateEditorType(campaignTemplate.editorType);
        setTemplateEditing(true);
      }
    }
  }, [activeStep, campaignTemplate]);

  return {
    details: campaignData,
    isCampaignLoading,
    firstIncompleteStep,
    steps,
    clearRequireSave,
    setActiveStep,
    headerActions,
    headerControlAction,
    quitCampaign,
    templateEditing,
    templateLoaded,
    title,
    toggleTemplateHistory,
    campaignStatus,
    config,
    campaignData,
    activeStep,
    activityId,
    campaignId: id,
    closePreviewDialog,
    closeSaveTemplateDialog,
    closeSendEmailTest,
    closeScheduleDialog,
    emailMessage,
    openPreviewDialog,
    openSavetemplateDialog,
    openScheduleDialog,
    openSendEmailTest,
    previewDialogOpen,
    refetchCampaign,
    requireSave,
    saveTemplateDialogOpen,
    sendEmailTestOpen,
    selectedTemplate,
    templateHtml,
    templateCss,
    quitCampaignVisible,
    hideQuitCampaign,
    saveStep,
    setDetailsCompleted,
    saveCampaign,
    saveDetails,
    isCampaignCreated,
    notifyRequireSave,
    handleTemplateChange,
    handleTemplateLoaded,
    handleTemplateUnLoaded,
    hideTemplateHistory,
    selectTemplate,
    stripoRef,
    templateEditorType,
    templateHistoryVisible,
    campaignTemplate,
    refetchTemplateSections,
    selectCustomTemplate,
    scheduleDialogOpen,
    cancelScheduledCampaign,
    startCampaign,
    screenLoading,
    setScreenLoading,
  };
}
